<template>
  <checklist-display :checklistId="checklistId"></checklist-display>
</template>

<script>
import { mapGetters } from 'vuex'
import ChecklistDisplay from '@/components/Tournament/Checklist/Display'
export default {
  props: ['checklistId'],
  computed: {
    ...mapGetters(['tournament', 'user'])
  },
  components: {
    ChecklistDisplay
  }
}
</script>
