<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="breadcrumbs">
          <v-icon slot="divider" class="xsmall">fas fa-chevron-right</v-icon>
          <template slot="item" slot-scope="props">
            <v-btn
              color="color3"
              class="pa-0 ma-0 xsmall"
              style="min-width: 0"
              text
              :to="props.item.to"
              exact
              small
              :disabled="props.item.disabled"
            >{{props.item.text}}</v-btn>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-expand-transition>
          <v-card v-if="theList">
            <v-toolbar color="color1 color1Text--text" :dark="color1IsDark" extended extension-height="58">
              <v-toolbar-title>{{theList.name}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <template v-slot:extension>
                <v-text-field
                  color="color3"
                  append-icon="fas fa-search"
                  label="Search"
                  hide-details
                  v-model="search"
                  solo-inverted
                  clearable
                ></v-text-field>
              </template>
            </v-toolbar>
            <v-container fluid class="pt-0" v-if="view.adminAndRefs">
              <v-row dense>
                <v-col cols="12" class="subheading d-flex" v-if="!allComplete">
                  Completion status: {{teamsSum ? `${teamsSum.checkedIn} of ${teamsSum.teams} (${teamsSum.teams - teamsSum.checkedIn} remaining)` : ''}}
                  <v-switch
                    label="Hide complete"
                    v-model="hideIn"
                    dense hide-details
                    class="mt-0 shrink ml-3"
                    color="success"
                  ></v-switch>
                </v-col>
                <v-col cols="12" v-if="!allComplete">
                  <v-chip
                    v-for="d in divisions" :key="d.id"
                    @click.stop="filterByChip(d.id)"
                    :color="d.complete ? 'green' : 'red'"
                    text-color="white"
                    class="mr-1"
                  >
                    {{d.name}} &nbsp;<b class="ml-1">{{d.checkedIn}}/{{d.teams}}</b>
                    <v-icon v-if="false && d.complete" right>fas fa-check-circle</v-icon>
                    <v-icon v-if="false && !d.complete" right>fas fa-exclamation-circle</v-icon>
                    <v-icon right v-if="filter === d.id" small>fas fa-filter</v-icon>
                  </v-chip>
                </v-col>
                <v-col cols="12" v-else>
                  <v-alert type="success" :value="true" dismissible>
                    Everyone is checked in!
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-data-table
                    v-if="listItems"
                    :items="displayListItems"
                    :headers="headers"
                    :loading="loading"
                    :options.sync="page"
                    :search="search"
                    class="elevation-1"
                    :hide-default-header="$vuetify.breakpoint.xs"
                    dense
                    @current-items="getFiltered"
                  >
                    <template v-slot:progress>
                      <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
                    </template>
                    <template v-slot:[`item.team.playerNames`]="{ item }">
                      <table class="nested">
                        <tr  v-for="(player) in item.team.players" :key="player.id">
                          <td class="text-left pl-0">
                            <player-link
                              :player="player"
                              :key="player.id"
                              :emitter="true"
                              @link-click="selectTeam(item.team, player.playerProfileId)"
                            ></player-link>
                          </td>
                        </tr>
                      </table>
                    </template>
                    <template v-slot:[`item.who`]="{item}">
                      {{item.in ? item.who : null}}
                    </template>
                    <template v-slot:[`item.in`]="{item}">
                      <v-btn
                        :color="item.in ? 'success' : 'grey'"
                        text icon
                        @click.stop="checkIn(item)"
                        :loading="checkingIn.includes(item.tournamentTeamId)"
                      >
                        <v-icon>{{item.in ? 'fas fa-check-square' : 'far fa-square'}}</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-dialog
                    v-model="dialog"
                    scrollable
                    max-width="500px"
                    transition="dialog-transition"
                  >
                    <v-card>
                      <v-toolbar color="color2 color2Text--text">
                        <v-toolbar-title>Who?</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="color1 color1Text--text"
                          small fab
                          @click.stop="dialog = false"
                        >
                          <v-icon>fas fa-times</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text v-if="selectedTeam">
                        <div class="text-h6 pt-3">Who is {{theList.note}}?</div>
                        <div class="pb-3">Click a player or enter a name below</div>
                        <div class="py-3 d-flex justify-center">
                          <v-btn
                            v-for="p in selectedTeam.team.players" :key="p.id"
                            color="color3 color3Text--text"
                            class="mr-2"
                            small
                            @click.stop="selectedTeam.who = p.fullname"
                          >
                            {{p.fullname}}
                          </v-btn>
                        </div>
                        <v-text-field
                          label="Name"
                          v-model="selectedTeam.who"
                          color="color3"
                          clearable
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="justify-end" v-if="selectedTeam">
                        <v-btn
                          color="success"
                          small fab
                          :disabled="!selectedTeam.who"
                          @click.stop="doCheckin(selectedTeam)"
                        >
                          <v-icon>fas fa-save</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <team-message-button
                    :tournamentId="tournament.id"
                    :teams="notifyItems"
                    :xs="true"
                  ></team-message-button>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import sum from 'lodash.sum'
import PlayerLink from '@/components/Player/PlayerLink.vue'
const TeamMessageButton = () => import('@/components/Notifications/TeamMessageButton')

export default {
  props: ['tournamentId', 'checklistId'],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: 'Teams',
          exact: true,
          disabled: true
        }
      ],
      search: null,
      dialog: false,
      checkingIn: [],
      filter: null,
      loading: false,
      page: { itemsPerPage: 25, sortBy: ['division.name'] },
      selectedTeam: null,
      notifyItems: [],
      hideIn: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'color1IsDark', 'getTeam']),
    theList () {
      return this.tournament.checklists && this.tournament.checklists.find(f => f.id === +this.checklistId)
    },
    tournamentTeams () {
      return this.tournament && this.tournament.teams
    },
    listItems () {
      const l = this.theList
      const r = []
      this.tournament.publicDivisions.forEach(d => {
        r.push(...d.activeTeams.map(m => {
          const x = l.items.find(f => f.tournamentTeamId === m.id) || {
            tournamentTeamId: m.id,
            playerId: null,
            who: null,
            in: false
          }
          x.team = this.getTeam(m.id)
          x.division = d
          return x
        }))
      })
      return r
    },
    displayListItems () {
      var base = this.filter ? this.listItems.filter(f => f.division.id === this.filter) : this.listItems
      if (this.hideIn) {
        base = base.filter(f => !f.in)
      }
      return base
    },
    headers () {
      const heads = [
        { text: 'Division', value: 'division.name' },
        { text: 'Team', value: 'team.name' },
        { text: 'Players', value: 'team.playerNames' },
        { text: 'Complete', value: 'in', align: 'center' },
        { text: 'Completed By', value: 'who', hide: !(this.theList && this.theList.askWho) }
      ]

      return heads
    },
    fullyHydrated () {
      return this.tournament.publicDivisions.filter(f => !f.hydrated).length === 0
    },
    divisions () {
      return this.tournament.publicDivisions.map(d => {
        return {
          id: d.id,
          name: d.name,
          get complete () {
            return this.teams === this.checkedIn
          },
          teams: this.listItems.filter(f => f.division.id === d.id).length,
          checkedIn: this.listItems.filter(f => f.division.id === d.id && f.in).length
        }
      })
    },
    teamsSum () {
      return this.divisions && {
        teams: sum(this.divisions.map(m => m.teams)),
        checkedIn: sum(this.divisions.map(m => m.checkedIn))
      }
    },
    allComplete () {
      return this.divisions.filter(f => !f.complete).length === 0
    },
    checkin () {
      return this.$route.query.checkin
    },
    ttid () {
      return this.$route.query.ttid
    },
    regId () {
      return this.$route.query.trid
    },
    email () {
      return this.$route.query.e
    }
  },
  methods: {
    filterByChip (id) {
      if (this.filter === id) {
        this.filter = null
      } else {
        this.filter = id
      }
    },
    getList () {
      this.loading = true
      this.$VBL.tournament.checklists.get(this.checklistId)
        .then(r => this.tournament.updateOrAddChecklist(r.data))
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    askWho (item) {
      this.selectedTeam = item
      this.dialog = true
    },
    checkIn (item) {
      if (this.theList.askWho && !item.in) {
        this.askWho(item)
        return
      }
      this.doCheckin(item)
    },
    doCheckin (item) {
      const dto = {
        tournamentTeamId: item.tournamentTeamId,
        in: !item.in,
        playerId: item.playerId,
        who: item.who
      }
      this.checkingIn.push(item.tournamentTeamId)
      this.$VBL.tournament.checklists.putItem(this.checklistId, dto)
        .then(r => this.tournament.updateOrAddChecklist({ id: this.checklistId, items: r.data }))
        .catch(e => console.log(e.response))
        .finally(() => {
          this.checkingIn = this.checkingIn.filter(f => f !== item.tournamentTeamId)
        })
      this.dialog = false
    },
    getFiltered (e) {
      this.notifyItems = e // .map(m => m.team)
    }
  },
  components: {
    PlayerLink,
    TeamMessageButton
  },
  mounted () {
    this.getList()
  }
}
</script>
